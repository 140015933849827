let allRoutes = [
  "Upcomings",
  "InHouse",
  "Completed",
  "Need Action",
  "Pending",
  "Room Status",
  "Expense",
  "Expense Revenues",
  "Expense Transctions",
  "Balance Sheet",
  "Loans And Advance",
  "Profit And Loss",
  "User Analytics",
  "Booking Users",
  "Booking Sales",
  "Check In Analytics",
  "Check Out Analytics",
  "Feedback(n) Analytics",
  "Revenue Analytics",
  "Collection Analytics",
  "Sales Analytics",
  "Feedback Analytics",
  "Extra Graphs",
  "Revenue Reconcile",
  "Collection Concile",
  "Task Groups",
  // "Task"
];
export default allRoutes;
