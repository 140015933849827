import axios from "axios"

const TaskRequester = async (route, token, data,method) => {


    try {

        const response = await axios({
            method: method,
            url: route,
            headers: {
                "Authorization": `Bearer ${token}`
            },
            data: {
                ...data
            },
            params:{
                ...(method.toLowerCase() === "get" ? data : {})
            }
        })
      
        return response.data
    } catch (err) {
        console.log(err)
        return err.response ? (err.response.data ? err.response.data : {}) : {}
    }
}

export default TaskRequester