import styled from "@emotion/styled";
import {
  AccountCircleOutlined,
  AttachFile,
  Bolt,
  EmojiEmotionsOutlined,
  // Send,
} from "@mui/icons-material";
import {
  // Button,
  IconButton,
  InputBase,
  Stack,
  // Typography,
} from "@mui/material";

const ChatBox = styled("div")({
  // backgroundColor: "#F4F6FA",
  // width: "670px",
  fontSize: "50%",
});

const ChatFooter = () => {
  return (
    <Stack
      bgcolor={"#F4F6FA"}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"space-between"}
      gap={1}
      padding={"5px"}
      borderRadius={"10px"}
    >
      <ChatBox>
        <InputBase
          placeholder="Type your message..."
          sx={{ paddingX: "8px", paddingY: "8px", width: "100%" }}
        />
      </ChatBox>
      <Stack
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={1}
      >
        <IconButton>
          <AttachFile />
        </IconButton>
        <IconButton>
          <EmojiEmotionsOutlined />
        </IconButton>
        <IconButton>
          <Bolt />
        </IconButton>
        <IconButton>
          <AccountCircleOutlined />
        </IconButton>
        {/* <Button variant="contained" endIcon={<Send />}>
          <Typography variant="subtitle1">Send</Typography>
        </Button> */}
      </Stack>
    </Stack>
  );
};
export default ChatFooter;
