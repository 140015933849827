import { Menu as MenuIcon } from "@mui/icons-material";
import {
  IconButton,
  InputBase,
  Stack,
  Typography,
  styled,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState } from "react";

const Search = styled("div")(({ theme }) => ({
  backgroundColor: "#F4F6FA",
  borderRadius: theme.shape.borderRadius,
  width: "400px",
  fontSize: "50%",
}));

const ChatTopBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      marginBottom={"10px"}
      paddingBottom={"6px"}
      borderBottom={"1px solid #ccc"}
    >
      <Typography
        variant="h6"
        fontSize={"18px"}
        sx={{
          maxWidth: "160px",
          marginLeft: "5px",
        }}
        noWrap
      >
        Print Bank Statement
      </Typography>
      <Search
        sx={{
          borderRadius: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.3) 0px 1px 2px 0px",
        }}
      >
        <InputBase
          placeholder="Search..."
          sx={{
            paddingX: "12px",
            paddingY: "6px",
            width: "100%",
          }}
        />
      </Search>
      <IconButton onClick={handleClick}>
        <MenuIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>Update Task</MenuItem>
        <MenuItem onClick={handleClose}>Settings</MenuItem>
      </Menu>
    </Stack>
  );
};
export default ChatTopBar;
