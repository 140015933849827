import allRoutes from "../../../pages/routes";
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
} from "@mui/material";
import ChatSideBar from "../component/ChatSideBar";
import CreateTask from "../component/CreateTask";
import ChatContainer from "../component/ChatContainer";

const TaskUserScreen = ({ selRoute }) => {
  // if (selRoute !== allRoutes[26]) {
    return;
  // }

  return (
    <Stack
      sx={{
        height: "100%",
      }}
      direction={"row"}
    >
      <Stack
        gap={"20px"}
        sx={{
          width: "29%",
          margin: "10px",
          height: "calc(100% - 20px)",
          overflowY: "scroll",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Stack sx={{ border: "1px solid #ccc", borderRadius: "5px" }}>
          <Accordion sx={{ height: "auto" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography variant="h6">Create Task</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CreateTask />
            </AccordionDetails>
          </Accordion>
        </Stack>

        <Stack
          sx={{
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <Accordion defaultExpanded={true}>
            <Typography
              variant="h6"
              sx={{
                padding: "12px 20px",
              }}
            >
              Tasks
            </Typography>
            <AccordionDetails>
              <Stack
                gap={1}
                sx={{
                  paddingBottom: "30px",
                  maxHeight: "460px",
                  overflowY: "scroll",
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <ChatSideBar />
                <ChatSideBar />
                <ChatSideBar />
                <ChatSideBar />
                <ChatSideBar />
                <ChatSideBar />
                <ChatSideBar />
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </Stack>

      <Stack
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        <ChatContainer />
      </Stack>
    </Stack>
  );
};

export default TaskUserScreen;
