import {
  Box,
  Button,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";

const TaskBox = styled(Box)({
  width: "100%",
  height: "auto",
});
const SubmitButton = styled(Button)({
  backgroundColor: "black",
  padding: "14px",
});

const CreateTask = () => {
  const [priority, setPriority] = useState("Medium");
  const [dueDateError, setDueDateError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const taskName = formData.get("title");
    const description = formData.get("description");
    const dueDate = formData.get("dueDate");

    const dueDateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!dueDateRegex.test(dueDate)) {
      setDueDateError(true);
      return;
    }
    setDueDateError(false);

    console.log("Task Name:", taskName);
    console.log("Description:", description);
    console.log("Due Date:", dueDate);
    console.log("Priority:", priority);

    e.target.reset();
  };

  return (
    <TaskBox>
      <form onSubmit={handleSubmit}>
        <Stack spacing={2} marginY={2}>
          <TextField
            name="title"
            required
            label="Task Name"
            variant="outlined"
          />
          <TextField
            name="description"
            label="Description"
            variant="outlined"
            multiline
            required
          />
          <TextField
            error={dueDateError}
            name="dueDate"
            label="Due Date"
            variant="outlined"
            placeholder="DD-MM-YYYY"
            required
            inputProps={{
              pattern: "\\d{2}-\\d{2}-\\d{4}",
              title: "Please enter a valid date in the format DD-MM-YYYY",
            }}
          />

          <Select
            labelId="priority"
            id="priority"
            label="Priority"
            value={priority}
            onChange={(e) => setPriority(e.target.value)}
            placeholder="Priority"
          >
            <MenuItem value={"High"}>High</MenuItem>
            <MenuItem value={"Medium"}>Medium</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
          </Select>
          <FormHelperText sx={{ fontStyle: "italic", fontWeight: "600" }}>
            Select priority
          </FormHelperText>

          <Button variant="contained" sx={{ padding: "14px" }} type="submit">
            <Typography>Create Task</Typography>
          </Button>
        </Stack>
      </form>
    </TaskBox>
  );
};
export default CreateTask;
