import { Stack, Typography, styled } from "@mui/material";

const MessageBox = styled(Stack)({
  backgroundColor: "#F4F6FA",
  width: "fit-content",
  padding: "10px",
  border: "1px solid #e0dbdb",
});

let i = 0;

const MessageContainer = ({ isUser }) => {
  return (
    <MessageBox
      sx={{
        alignSelf: isUser && "flex-end",
        borderRadius: isUser ? "15px 15px 0" : "0 15px 15px",
      }}
      gap={"4px"}
    >
      <Typography color={isUser ? "dodgerblue" : "cadetblue"} variant="body1">
        Rehan Fazal
      </Typography>
      <Typography variant="body2" sx={{ fontSize:"14px",fontWeight:"400",textShadow:"none" }}  >
        Hello my name is Rehan.
      </Typography>
      <Typography variant="body2" color={"dimgray"} sx={{
        fontSize:"10px"
      }}>
        12:0{i++} PM
      </Typography>
    </MessageBox>
  );
};
export default MessageContainer;
